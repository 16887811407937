import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ImmunizationSeriesEvent } from "../models/Interfaces";

interface EventCardProps {
  index: number;
  event: ImmunizationSeriesEvent;
  vaccine: string;
}

export default function EventCard({ event, vaccine, index }: EventCardProps) {
  const { t } = useTranslation();

  const readableDate = (date: string) => {
    return moment(date).format("L");
  };

  return (
    <div className="box event-card">
      <div className=" columns is-gapless is-vcentered ">
        <div className="column">
          {(event.description || vaccine) && (
            <div
              role="paragraph"
              className="is-size-4 has-text-weight-bold is-family-primary has-text-dark"
              data-testid="event-card-title"
            >
              {`${event.description || vaccine}`}
            </div>
          )}
          {event.dateAdministered && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-administered"
            >
              <b>{`${t("immunizations.records_event_administered")}`}</b>
              {readableDate(event.dateAdministered)}
            </div>
          )}
          {event.administeredAtFacility && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-facility"
            >
              <b>{`${t("immunizations.records_event_provider")}`}</b>
              {event.administeredAtFacility}
            </div>
          )}
          {event.manufacturer && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-manufacturer"
            >
              <b>{`${t("immunizations.records_event_manufacturer")}`}</b> {event.manufacturer}
            </div>
          )}
          {event.lot && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-lot"
            >
              <b>{`${t("immunizations.records_event_lot")}`}</b> {event.lot}
            </div>
          )}
          {parseInt(event?.doseNumberInSeries || "0") > 0 && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-dose"
            >
              <b>{`${t("immunizations.records_event_dose_number")}`}</b> {event.doseNumberInSeries}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
