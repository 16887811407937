import { TFunction } from "i18next";
import DefaultIzProvider from "./DefaultIzProvider";

export class Utah extends DefaultIzProvider {
  constructor(izDestinationId: string, t: TFunction) {
    super(izDestinationId, t);
    this.scenarioBImagePath = "/assets/images/large/Docket_Utah_scb_f.png";
    this.reportTitles = [
      t("immunizations.records_history_report_ut_school"),
      t("immunizations.records_history_report_ut_forecast"),
      t("immunizations.records_history_report_ut_history"),
      t("immunizations.records_history_report_ut_personal"),
    ];
  }
}
