import { IDocketAPI } from "../apiClient";
import { Auth, signOut } from "firebase/auth";
import Bugsnag from "@bugsnag/js";
import db, { Key } from "../database";
import { warnLog } from "../utils/logger";
import { clearPrefs } from "../jotai/atomUpdater";
import tokenSupplier from "../docket-apis/tokens/token-supplier";

/**
 * Log the user out.
 *
 * @param api: Acquired with `getAPIClient()` to attempt to log the user out.
 * @param firebaseAuth: Pass in if we need to logout of Firebase, otherwise omit.
 */
export default async function logout(api: IDocketAPI, firebaseAuth?: Auth): Promise<void> {
  try {
    await api.deleteToken((await tokenSupplier())?.refresh);
  } catch (err) {
    // do nothing.
    // It's possible this is being called when we can't refresh a token,
    // which means it's very unlikely the user is going to be able to delete the token.
  }

  if (firebaseAuth) {
    try {
      if (firebaseAuth.currentUser !== null) {
        await signOut(firebaseAuth);
      }
    } catch (err: any) {
      warnLog(err);
      Bugsnag.notify(err);
    }
  }

  //  resetLoginSlice();
  //  resetImmunizationsSlice();
  // There is no `.clearUser` for the web. Whatever.
  Bugsnag.setUser("", "", "");

  await clearPrefs();
  await db().clear();
}
