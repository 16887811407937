import {
  AuthenticationApiFactory,
  RailsLegacyV2ApiFactory,
} from "@docket/consumer-app-client-typescript-axios";
import baseConfig from "./config/base-config";
import axios from "axios";
import withAccessToken from "./config/with-access-token";
import { RefreshingTokenProvider } from "./tokens/refreshing-token-provider";
import tokenSupplier from "./tokens/token-supplier";
import tokenStorer from "./tokens/token-storer";

/**
 * This file is responsible for configuring and exporting the generated OpenAPI clients for Docket.
 */

const basicConfig = baseConfig();
/**
 * First we have to instantitate the auth API using just the basic configuration. We cannot instantitate it with the
 * access token configuration because none of the APIs are authenticated (the auth APIs exist in order to authenticate,
 * after all) and we use the auth API in order to provide access tokens to _other_ APIs via the access token
 * configuration.
 *
 */
const authApi = AuthenticationApiFactory(basicConfig, undefined, axios);
const tokenProvider = new RefreshingTokenProvider(authApi, tokenSupplier, tokenStorer);
/**
 * Now that we have an instance of the auth API and a token provider that wraps it, we can create the configuration used
 * by all other APIs. This configuration will provide an access token for routes that need it.
 */
const accessTokenConfig = withAccessToken(tokenProvider);

const railsApi = RailsLegacyV2ApiFactory(accessTokenConfig, undefined, axios);

export interface DocketApis {
  railsLegacyV2: ReturnType<typeof RailsLegacyV2ApiFactory>;
  auth: ReturnType<typeof AuthenticationApiFactory>;
}

/**
 * We export a function that returns an object with a key:value pair per API. This construction is used mostly to
 * facilitate easy mocking/spying of the APIs in jest.
 */
export const DocketApis: () => DocketApis = () => ({
  railsLegacyV2: railsApi,
  auth: authApi,
});
