import { LegacyPatientDto } from "@docket/consumer-app-client-typescript-axios";
import { LegacyGetRecordDataDtoWithIntId, LegacyGetSearchDataDtoWithIntId } from "../apiClient";
import * as interfaces from "../models/Interfaces";
import {
  ImmunizationPatient,
  ImmunizationPatientContact,
  ImmunizationPatientReport,
  ImmunizationRecord,
  ImmunizationSearch,
  ImmunizationSeries,
  ImmunizationSeriesEvent,
} from "../models/Interfaces";
import moment from "moment";

export const reports = [
  "school_report",
  "forecast_report",
  "personal_report",
  "history_report", // default
];

/**
 * createSearches
 *
 * @remarks
 * Parses the API response for getIzSearches and returns Array<ImmunizationSearch>
 *
 * @returns promise an array of ImmunizationSearch objects
 *
 */
export const createSearches = (data: LegacyGetSearchDataDtoWithIntId[]): ImmunizationSearch[] => {
  const newSearches = data
    .sort(
      (a, b) =>
        moment(b.attributes.created_at).valueOf() - moment(a.attributes.created_at).valueOf()
    )
    .map(searchBuilder);
  return newSearches;
};

export const searchBuilder = (search: LegacyGetSearchDataDtoWithIntId) => {
  const newSearch = search.attributes;
  const theSearch: interfaces.ImmunizationSearch = {
    firstName: newSearch.first_name ?? "",
    lastName: newSearch.last_name ?? "",
    dateOfBirth: newSearch.dob ?? "",
    isChildSearch: !!newSearch.child_dob ? true : false,
    legalSex: newSearch.legal_sex ?? "",
    childDateOfBirth: newSearch.child_dob ?? "",
    childFirstName: newSearch.child_first_name ?? "",
    childLastName: newSearch.child_last_name ?? "",
    childLegalSex: newSearch.child_legal_sex ?? "",
    dateUpdated: newSearch.updated_at ?? "",
    dateVerified: newSearch.verified_at ?? "",
    patients: createPatients(
      newSearch.patients,
      newSearch.uid,
      newSearch.iz_provider_id,
      newSearch.iz_provider_key
    ),
    contacts: [] as ImmunizationPatientContact[],
    recordsFound: 0,
    status: newSearch.status,
    whoAmI: newSearch.who_am_i ?? "",
    uid: newSearch.uid,
    izProviderKey: newSearch.iz_provider_key,
    izProviderId: newSearch.iz_provider_id,
  };

  theSearch.contacts = theSearch.patients.flatMap((patient) => {
    if (patient.contacts) {
      return patient.contacts;
    } else {
      return [];
    }
  });
  return theSearch;
};

/**
 * createPatients
 *
 * @remarks
 * Parses the API response for getIzSearches patient data, PatientAPI[], and
 * returns Array<ImmunizationPatient>
 *
 * @returns promise an array of ImmunizationPatient objects
 *
 */
const createPatients = (
  patients: LegacyPatientDto[],
  searchUid: string,
  izProviderId: number,
  izProviderKey: string
): ImmunizationPatient[] => {
  let result: ImmunizationPatient[] = [] as ImmunizationPatient[];
  if (!patients || patients.length === 0) {
    return result;
  }

  return patients.map((p) => {
    return {
      firstName: p.first_name ?? "",
      lastName: p.last_name ?? "",
      patientId: p.patient_id ?? "",
      dob: p.dob ?? "",
      searchUid: searchUid,
      izProviderId: izProviderId,
      izProviderKey: izProviderKey,
      contacts: setNewPatientContacts(p.phone_numbers, p.email_addresses),
    };
  });
};

const setNewPatientContacts = (phoneNumbers: Array<string>, emailAddresses: Array<string>) => {
  const contacts = Array<ImmunizationPatientContact>();
  if (phoneNumbers && phoneNumbers.length > 0) {
    phoneNumbers.forEach((phone) => {
      contacts.push({ type: "phone", value: phone, maskedValue: phone });
    });
  }
  if (emailAddresses && emailAddresses.length > 0) {
    emailAddresses.forEach((email) => {
      contacts.push({ type: "email", value: email, maskedValue: email });
    });
  }
  return contacts;
};

export const createNewRecord = (
  search: ImmunizationSearch,
  record: LegacyGetRecordDataDtoWithIntId
): ImmunizationRecord => {
  const result: ImmunizationRecord = {
    uid: record.attributes.uid,
    searchUid: record.attributes.iz_search_uid,
    izProviderId: record.attributes.iz_provider_id,
    izProviderKey: record.attributes.iz_provider_key,
    patientId: record.attributes.patient_id,
    createdAt: record.attributes.created_at,
    updatedAt: record.attributes.updated_at,
    dequeuedAt: record.attributes.dequeued_at,
    discardedAt: record.attributes.discarded_at ?? undefined,
    firstName: search.isChildSearch ? search.childFirstName : search.firstName,
    lastName: search.isChildSearch ? search.childLastName : search.lastName,
    immunizations: [],
  };

  for (const apiSeries of record.attributes.immunizations || []) {
    const parsedEvents: ImmunizationSeriesEvent[] = [];
    for (const event of apiSeries.events) {
      const parsedEvent: ImmunizationSeriesEvent = {
        recordId: record.attributes.uid,
        dateAdministered: event.date_administered ?? undefined,
        doseNumberInSeries: `${event.dose_number_in_series || ""}`,
        manufacturer: event.manufacturer || "",
        lot: event.lot || "",
        description: event.description || "",
        administeredAtFacility: event.administered_at_location.facility || "",
        administeredAtStreet: event.administered_at_location.street_address || "",
        administeredAtCity: event.administered_at_location.city || "",
        administeredAtState: event.administered_at_location.state || "",
        administeredAtZip: event.administered_at_location.zip || "",
      };
      parsedEvents.push(parsedEvent);
    }
    const parsedSeries: ImmunizationSeries = {
      recordUid: record.attributes.uid,
      searchUid: search.uid,
      vaccineType: apiSeries.vaccine_type!,
      status: apiSeries.status,
      events: parsedEvents,
      dateDue: apiSeries.date_vaccination_due ?? undefined,
      dateOverdue: apiSeries.date_dose_is_overdue ?? undefined,
      earliestDateToGive: apiSeries.earliest_date_to_give ?? undefined,
      latestDateToBeGiven: apiSeries.latest_date_next_dose_should_be_given ?? undefined,
    };
    result.immunizations.push(parsedSeries);
  }
  return result;
};

export const setDefaultReports = (firstName: string, lastName: string) => {
  return reports
    .map((report) => `${report}_${firstName}_${lastName}`)
    .reduce((arr, report) => {
      arr.push({ reportName: report, reportStatus: "available", filePath: "" });
      return arr;
    }, [] as ImmunizationPatientReport[]);
};
