import React, { ReactNode, createContext, useEffect } from "react";
import { brandingConfig, BrandingConfig } from "./branding.config";
import { WHITELABEL_KEY, USE_WHITELABEL_BRANDING } from "../globals";
import { generateBrandingStyles } from "./brandingStyles";

export const BrandingContext = createContext<BrandingConfig>({} as BrandingConfig);

interface BrandingProviderProps {
  children: ReactNode;
}

const BrandingProvider: React.FC<BrandingProviderProps> = ({ children }) => {
  const branding: BrandingConfig = USE_WHITELABEL_BRANDING
    ? brandingConfig[WHITELABEL_KEY] || brandingConfig["default"]
    : brandingConfig["default"];

  useEffect(() => {
    // Apply branding styles
    const style = document.createElement("style");
    style.textContent = generateBrandingStyles(branding);
    document.head.appendChild(style);

    // Update theme-color meta tag
    let themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (!themeColorMeta) {
      themeColorMeta = document.createElement("meta");
      themeColorMeta.setAttribute("name", "theme-color");
      document.head.appendChild(themeColorMeta);
    }
    themeColorMeta.setAttribute("content", branding.siteBackground);

    // Favicon and installed app options
    const elementsToUpdate: HTMLElement[] = [];

    const icon =
      (document.querySelector("link[rel='icon']") as HTMLLinkElement) ||
      document.createElement("link");
    icon.type = "image/svg+xml";
    icon.rel = "icon";
    icon.href = `${branding.faviconPrefix}/favicon.svg`;
    elementsToUpdate.push(icon);

    const shortcutIcon =
      (document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement) ||
      document.createElement("link");
    shortcutIcon.rel = "shortcuticon";
    shortcutIcon.href = `${branding.faviconPrefix}/favicon.ico`;
    elementsToUpdate.push(shortcutIcon);

    const appleTouchIcon =
      (document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement) ||
      document.createElement("link");
    appleTouchIcon.rel = "apple-touch-icon";
    appleTouchIcon.href = `${branding.faviconPrefix}/apple-touch-icon.png`;
    elementsToUpdate.push(appleTouchIcon);

    const appleMobileWebAppTitle =
      (document.querySelector("meta[name='apple-mobile-web-app-title']") as HTMLMetaElement) ||
      document.createElement("meta");
    appleMobileWebAppTitle.name = "apple-mobile-web-app-title";
    appleMobileWebAppTitle.content = branding.title;
    elementsToUpdate.push(appleMobileWebAppTitle);

    const manifest =
      (document.querySelector("link[rel='manifest']") as HTMLLinkElement) ||
      document.createElement("link");
    manifest.rel = "manifest";
    manifest.href = `${branding.faviconPrefix}/site.webmanifest`;
    elementsToUpdate.push(manifest);

    const title =
      (document.querySelector("title") as HTMLTitleElement) || document.createElement("title");
    title.textContent = branding.title;
    elementsToUpdate.push(title);

    for (const element of elementsToUpdate) {
      if (!element.parentNode) {
        document.head.appendChild(element);
      }
    }

    return () => {
      document.head.removeChild(style);
    };
  }, [branding]);

  return <BrandingContext.Provider value={branding}>{children}</BrandingContext.Provider>;
};

export default BrandingProvider;
