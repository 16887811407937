import db, { Key } from "../../database";
import { Tokens } from "../../models/Interfaces";

export default async () => {
  /**
   * XXX: This method reads directly from the database instead of using the Jotai atom for tokens. This is done because
   * the Jotai storage atoms are initialized with a default value of `null`. That means if you log in and then refresh
   * the page, you'll get `null` from the Atom. Because this code sits outside of any React component, it's a bit harder
   * to control and so we fallback to reading from the database.
   */
  return db().getItem<Tokens>(Key.Tokens);
};
