import React from "react";
import { useTranslation } from "react-i18next";
import "./disclaimerCard.css";
import { Modal } from "../modals/Modal";

interface DisclaimerModalProps {
  closeModal: () => void;
  stateCode: string;
  screening?: boolean;
}

export function DisclaimerStateModal({
  closeModal,
  stateCode,
  screening = false,
}: DisclaimerModalProps) {
  const { t } = useTranslation();

  const disclaimerTranslations = {
    consentTitle: t("immunizations.disclaimer_title"),
    recordsDisclaimer: t("immunizations.disclaimer_body", { context: stateCode }),
    screeningsDisclaimer: t("screening.disclaimer_body"),
  };

  return (
    <Modal
      onCloseModal={closeModal}
      hasClose={true}
      title={disclaimerTranslations.consentTitle}
      confirm={t("generic.ok")}
      onConfirm={() => closeModal()}
    >
      <p className="has-dark-text" style={{ whiteSpace: "pre-wrap" }}>
        {screening
          ? disclaimerTranslations.screeningsDisclaimer
          : disclaimerTranslations.recordsDisclaimer}{" "}
        {screening && (
          <a
            className="docket-link has-text-link"
            href="https://www.nj.gov/health/childhood-lead/"
            target="_blank"
          >
            https://www.nj.gov/health/childhood-lead/.
          </a>
        )}
      </p>
    </Modal>
  );
}
