import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  ScreeningDto,
  TelecommunicationDtoMethodEnum,
} from "@docket/consumer-app-client-typescript-axios";

interface EventCardProps {
  index: number;
  screeningEvent: ScreeningDto;
}

export default function ScreeningEventCard({ screeningEvent, index }: EventCardProps) {
  const { t } = useTranslation();

  const readableDate = (date: string) => {
    return moment.parseZone(date).format("L");
  };

  const hasOrderingFacilityData =
    screeningEvent.orderingFacility &&
    (screeningEvent.orderingFacility.names.length > 0 ||
      screeningEvent.orderingFacility.contactInfo.length > 0);

  return (
    <div className="box event-card">
      <div className=" columns is-gapless is-vcentered ">
        <div className="column">
          {screeningEvent.testDate && (
            <div
              role="paragraph"
              className="is-size-4 has-text-weight-bold is-family-primary has-text-dark"
              data-testid="event-card-title"
            >
              {readableDate(screeningEvent.testDate)}
            </div>
          )}
          {screeningEvent.type && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-specimen"
            >
              <b>{t("screening.records_event_specimen")}</b>
              {t("screening.results_lead", { context: screeningEvent.type })}
            </div>
          )}
          {screeningEvent.measurement && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-test-value"
            >
              <b>{t("screening.records_event_test_value")}</b> {screeningEvent.measurement}{" "}
              {screeningEvent.units ? screeningEvent.units : ""}
            </div>
          )}
          {screeningEvent?.laboratory && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark "
              data-testid="event-card-reference-value"
            >
              <b>{t("screening.records_event_laboratory")}</b> {screeningEvent.laboratory}
            </div>
          )}
          {hasOrderingFacilityData && (
            <div
              role="paragraph"
              className="is-size-5 is-family-primary has-text-dark"
              data-testid="event-card-ordering-facility"
            >
              <b>{t("screening.records_event_ordering_facility")}</b>
              {screeningEvent.orderingFacility.names.join(", ")}
              {screeningEvent.orderingFacility.contactInfo.map((contact, index) => (
                <>
                  {contact.method !== TelecommunicationDtoMethodEnum.Unknown && (
                    <div key={contact.value}>
                      <b>
                        {t(`screening.records_event_contact`, {
                          context: contact.method.toLowerCase(),
                        })}
                      </b>{" "}
                      {contact.value}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
