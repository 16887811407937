import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./disclaimerCard.css";
import getIzProvider from "../../models/providers/IzProviders";
import { DisclaimerStateModal } from "./DisclaimerStateModal";

interface DisclaimerCardProps {
  provider: string;
  screening?: boolean;
}

export default function DisclaimerCard({ provider, screening }: DisclaimerCardProps) {
  const { t } = useTranslation();
  const izProvider = getIzProvider(provider, t);
  const [disclaimerModal, setDisclaimerModal] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const disclaimer = "/assets/images/disclaimer_en.png";
  const disclaimer_es = "/assets/images/disclaimer_es.png";
  const mic = "/assets/images/reportMic.png";

  useEffect(() => {
    // getDisclaimerTapped().then((result) => {
    //   //dlog(`getDisclaimerView tapped result ${result} ${providerLc}`)
    //   if (result.includes(providerLc) && providerLc !== 'nj') {
    //     setShowDisclaimer(false)
    //   }
    // })
    return () => {};
  }, []);

  const getCardTitleText = () => {
    if (screening) {
      return t("screening.disclaimer_text");
    }
    return t("disclaimer.status", { iisAbbrev: izProvider.iisAbbrev });
  };

  if (showDisclaimer) {
    return (
      <>
        <div className="is-fullheight">
          <div className=" columns is-gapless disclaimer-card">
            <div className="disclaimer-badge-bg"></div>
            <div className="disclaimer-badge">{t("immunizations.disclaimer_title")}</div>

            <div className="column ml-6 ">
              <div
                role="paragraph"
                className="has-text-weight-semibold pt-2 is-family-primary has-text-dark mt-1 text-title"
                data-testid="disclaimer-card-title"
              >
                {getCardTitleText()}
              </div>

              <div className="is-flex is-flex-direction-row is-justify-content-space-between pr-4 ">
                <button
                  className="docket-button button mt-3 mb-3 pl-2 pr-2"
                  onClick={() => setDisclaimerModal(true)}
                >
                  {t("disclaimer.learn_more")}
                </button>

                <div className="is-2 mt-2 has-text-right is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-end">
                  <img className="disclaimer-card-mic-img" src={mic} alt="Microphone" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {disclaimerModal && (
          <DisclaimerStateModal
            closeModal={() => setDisclaimerModal(false)}
            stateCode={izProvider.jurisdictionAbbrev.toLowerCase()}
            screening={screening}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
}
