/* eslint-disable security/detect-object-injection */
import {
  LegacyAdministeredAtDto,
  LegacyDeleteSearchResponseDto,
  LegacyDeleteUserResponseDto,
  LegacyDeliverSearchPinRequestDto,
  LegacyDeliverSearchPinResponseDto,
  LegacyEnqueueSearchRequestDto,
  LegacyEnqueueSearchResponseDto,
  LegacyGetProviderConfigsResponseDto,
  LegacyGetRecordDataDto,
  LegacyGetSearchDataDto,
  LegacyGetUserResponseDto,
  LegacyUpdateUserResponseDto,
  LegacyVerifySearchPinRequestDto,
  LegacyVerifySearchPinResponseDto,
  LegacyVerifyUserPhoneCodeRequestDto,
  LegacyVerifyUserPhoneRequestDto,
  ScreeningDto,
  UserResponseDto,
} from "@docket/consumer-app-client-typescript-axios";
import { GoogleSignup, AppleSignUp } from "./models/Interfaces";
import { enumKeys, LEGACY_API_DOMAIN } from "./globals";
import ChainedCustomError from "typescript-chained-error";
import { PROTOCOL } from "./globals";
import _ from "lodash";
import logout from "./utils/logout";
import { DocketApis } from "./docket-apis/apis";
import axios from "axios";
import { assertNever } from "./utils/assert-never";

export interface IDocketAPI {
  /**
   * Attempt to sign in using Apple.

  /**
   * Attempt to create a user.
   * @param userInfo The signup data for the user
   * @return A UserResponseDto on success. Rejected promises will have an APIError.
   */
  loginApple(userInfo: AppleSignUp): Promise<UserResponseDto>;

  /**
   * Attempt to sign in using Google.

   * @param userInfo The signup data for the user
   * @return A UserResponseDto on success. Rejected promises will have an APIError.
   */
  loginGoogle(userInfo: GoogleSignup): Promise<UserResponseDto>;

  /**
   * Get the user data with the current login credentials.
   * @return the user
   */
  getUser(): Promise<LegacyGetUserResponseDto>;

  /**
   * Deletes the currently logged-in user along with everything about their account (e.g. searches, histories, preferences)
   */
  deleteUser(): Promise<LegacyDeleteUserResponseDto>;

  /**
   * Update the user. This can be any partial number of attributes that are part of the UserAccount.
   * @return true
   */
  updateUser(params: any): Promise<LegacyUpdateUserResponseDto>;

  /**
   * Send magic link
   * @param email The email
   * @return void
   */
  sendSignInLink(email: string): Promise<void>;

  /**
   * Login the user with magic link.
   * @param token firebase token
   * @return A UserResponseDto with a token on success. Rejected promises will have an APIError.
   */
  loginWithFirebase(token: string): Promise<UserResponseDto>;

  /**
   * Deletes refresh token. This should be done on log out to ensure the token family is cleaned up.
   * @return A resolved promise once the call is completed, rejected on error.
   */
  deleteToken(refreshToken?: string): Promise<void>;

  /**
   * Initiates login flow to the correct OIDC provider depending on state
   *
   * @param stateIdentifier state identifier, two letters (example: ak, ut)
   * @return void
   */
  initiateOidcAuth(stateIdentifier: string): Promise<void>;

  /**
   * Attempt to log in user from OIDC flow
   *
   * @param code string with code to use on auth flow
   * @param stateIdentifier state identifier, two letters (example: ak, ut)
   * @return A UserResponseDto object with a token on success.  Rejected promises will have an APIError
   */
  loginWithAuthCode(code: string, stateIdentifier: string): Promise<UserResponseDto>;

  /**
   * Attempt to verify that the user actually owns the phone number they pass in.
   *
   * @param phoneNumber The phone number to verify at the account level. Should be 10 digits, matching regex [0-9]{10}.
   * @return A promise that will resolve if the call was successful. This does NOT indicate
   * that the text message was successfully delivered, but that the backend considered this
   * an authorized request.
   */
  addUserAccountPhone(phoneNumber: LegacyVerifyUserPhoneRequestDto): Promise<void>;

  /**
   * This is the other half of `requestUserAccountPhone`.
   * @param code The verification code
   * @return An accepted promise if the code was valid, otherwise rejected.
   */
  verifyUserAccountPhone(code: LegacyVerifyUserPhoneCodeRequestDto): Promise<void>;

  /**
   * Get all the immunization searches for a user.
   * @return A list of all the searches
   */
  getIzSearches(iconTapped: boolean): Promise<LegacyGetSearchesResponseDtoWithIntId>;

  /**
   * Send a request to queue an immunization search
   * @param request The request
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return A promise with info about the queue
   */
  enqueueIzSearch(
    request: LegacyEnqueueSearchRequestDto,
    jurisdiction: string
  ): Promise<LegacyEnqueueSearchResponseDto>;

  /**
   * Get immunization search data
   * @param searchUid The search ID
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return Immunization search data, or null if the request isn't yet ready (e.g. still processing in backend queues)
   */
  getIzSearch(
    searchUid: string,
    jurisdiction: string
  ): Promise<null | LegacyGetSearchDataDtoWithIntId>;

  /**
   * Delete an immunization search
   * @param searchUid The search ID
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return A status with "ok" if it was successful, 400 otherwise, including if the search was already deleted.
   */
  deleteIzSearch(searchUid: string, jurisdiction: string): Promise<LegacyDeleteSearchResponseDto>;

  /**
   * Send a verification code for a specific immunization search. At least one of SMS, email, and voice are required.
   * @param searchUid The UID of the search
   * @param requestParameters The parameters
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return "OK" if the PIN was sent.
   */
  sendImmunizationSearchPIN(
    searchUid: string,
    requestParameters: LegacyDeliverSearchPinRequestDto,
    jurisdiction: string
  ): Promise<LegacyDeliverSearchPinResponseDto>;

  /**
   * The other half of `sendImmunizationSearchPIN`. A user should supply the PIN, and this will verify the search.
   * @param searchUid The search UID to verify
   * @param pin The PIN from the user that should match whatever was sent.
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return "OK" and the time if the PIN was verified successfully.
   */
  verifyImmunizationSearchPIN(
    searchUid: string,
    params: LegacyVerifySearchPinRequestDto,
    jurisdiction: string
  ): Promise<LegacyVerifySearchPinResponseDto>;

  /**
   * Get the immunization history for a corresponding search. A bit weird that we use the *search ID*
   * instead of the history ID.
   * @param searchUid The IZ search ID
   * @param jurisdiction The jurisdiction the search and corresponding record belongs to (eg 'nj', 'ut', 'ak')
   * @return
   */
  getIzRecord(searchUid: string, jurisdiction: string): Promise<LegacyGetRecordDataDtoWithIntId>;

  /**
   * Get immunization search data
   * @param searchUid The search ID
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return Immunization Screening data, or void if the request isn't yet ready (e.g. still processing in backend queues)
   */
  getIzScreeningRecord(searchUid: string, jurisdiction: string): Promise<ScreeningDto[]>;

  /**
   * Return a list of all available records for the user.
   * @return A promise with all the records
   */
  getAllIzRecords(): Promise<LegacyGetRecordsResponseDtoWithIntId>;

  /**
   * Ask the server to call the IIS and refresh the records.
   * @return The estimated wait time to refresh the records in seconds.
   */
  refreshRecords(): Promise<number>;

  /**
   * Get a PDF report of immunization data.
   * @param searchUid The immunization search ID.
   * @param reportType The type of PDF report to get.
   * @param jurisdiction The jurisdiction the search and corresponding record belongs to (eg 'nj', 'ut', 'ak')
   * @return A buffer of binary PDF data on success.
   */
  getRecordReport(
    searchUid: string,
    reportType: ReportType,
    jurisdiction: string
  ): Promise<ArrayBuffer>;

  /**
   * Get all of the IIS provider configurations. This should dynamically change what's available in the app
   * at run time.
   * @return A list of all of the configurations
   */
  getProviderConfigs(): Promise<LegacyGetProviderConfigsResponseDto>;
}

export enum LegalSex {
  Male = "M",
  Female = "F",
  Unknown = "U",
  Other = "X",
}

export function legalSexFromString(legalSex: string | null): LegalSex {
  if (!legalSex) {
    return LegalSex.Unknown;
  }

  //should return from this block for legalSex from search API call
  const upperLegalSex = legalSex.toUpperCase();
  for (const value of Object.values(LegalSex)) {
    if (upperLegalSex === value) {
      return upperLegalSex;
    }
  }

  //else we arrive here for login legal sex match which is full word...
  const fullLegalSexToEnum: Record<string, LegalSex> = {
    MALE: LegalSex.Male,
    FEMALE: LegalSex.Female,
    UNKNOWN: LegalSex.Unknown,
    OTHER: LegalSex.Other,
  };
  for (const key of Object.keys(fullLegalSexToEnum)) {
    if (key === upperLegalSex) {
      return fullLegalSexToEnum[key];
    }
  }
  throw new Error(`Could not determine legal sex for string '${legalSex}'`);
}

export enum MatchStatus {
  NoMatch = "NO_MATCH",
  Match = "MATCH",
  BasicMatchNoContacts = "BASIC_MATCH_NO_CONTACTS",
  PartialMatchAltContacts = "PARTIAL_MATCH_ALT_CONTACTS",
  PartialMatchNoContacts = "PARTIAL_MATCH_NO_CONTACTS",
  MultiMatch = "MULTI_MATCH",
  InQueue = "IN_QUEUE",
}

export function matchStatusFromString(matchStatus?: string): MatchStatus {
  if (!matchStatus || !Object.values(MatchStatus).includes(matchStatus as MatchStatus)) {
    return MatchStatus.NoMatch;
  }
  for (const value of Object.values(MatchStatus)) {
    if (matchStatus.toUpperCase() === value) {
      return value;
    }
  }
  throw new Error(`Could not find a match status for '${matchStatus}'`);
}

export enum ReportType {
  HISTORY_REPORT = "history_report", // default
  SCHOOL_REPORT = "school_report",
  FORECAST_REPORT = "forecast_report",
  PERSONAL_REPORT = "personal_report",
}

// TODO: Make sure these enum converters work as expected
export function reportTypeFromString(reportType: string): ReportType {
  const rt = reportType.toLowerCase();
  for (const key of enumKeys(ReportType)) {
    const val = ReportType[key];
    if (rt === val) {
      return val;
    }
  }
  throw new Error(`Could not find a report type that matches '${reportType}'`);
}

export interface SignupRequest {
  user: AppleSignUp | GoogleSignup;
}

export interface APIError {
  errors: string[];
}

export interface LegacyGetSearchesResponseDtoWithIntId {
  data: LegacyGetSearchDataDtoWithIntId[];
}

export interface LegacyGetSearchDataDtoWithIntId extends Omit<LegacyGetSearchDataDto, "id"> {
  id: number;
}

export interface LegacyGetRecordsResponseDtoWithIntId {
  data: LegacyGetRecordDataDtoWithIntId[];
}

export interface LegacyGetRecordDataDtoWithIntId extends Omit<LegacyGetRecordDataDto, "id"> {
  id: number;
}

export interface ImmunizationEvent {
  // Specifically a date, e.g. 1990-01-30
  date_administered: string | null;
  manufacturer: string | null;
  manufacturer_name: string | null;
  lot: string | null;
  administered_at_location: LegacyAdministeredAtDto;
  cvx_code: string;
  cpt_code: string | null;
  dose_status: string;
  dose_number_in_series: number | null;
  // administeredAtFacility?: string;
  // administeredAtStreet?: string;
  // administeredAtCity?: string;
  // administeredAtState?: string;
  // administeredAtZip?: string;
  description: string | null;
}

export class DocketAPIError extends ChainedCustomError {
  public constructor(msg: string, cause?: Error) {
    super(msg, cause);
  }
}

export class DocketAPIAlreadyEnqueuedError extends DocketAPIError {
  public constructor(msg: string) {
    super(msg);
  }
}

export class DocketCareAPIV1 implements IDocketAPI {
  private throwResponseError(err: any, defaultErrMsg: string): never {
    let errorMessage = defaultErrMsg;
    if (axios.isAxiosError(err)) {
      const error = err.response?.data["error"] || err.response?.data["errors"];
      if (Array.isArray(error)) {
        errorMessage = error.join(", ");
      } else {
        errorMessage = error.toString();
      }
    }
    throw new DocketAPIError(errorMessage, err);
  }

  async sendSignInLink(email: string): Promise<void> {
    try {
      return (await DocketApis().auth.sendMagicLink({ email: email })).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, failed to send Signin link.");
    }
  }

  async loginWithFirebase(token: string): Promise<UserResponseDto> {
    let result: UserResponseDto | null = null;
    try {
      result = (await DocketApis().auth.firebase({ token: token })).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, you were unable to login. Please try again later.");
    }

    if (result !== null && result.legal_sex) {
      // Super fun: legal sex is returned as a full string, e.g. "male", instead of "M" for this API.
      result["legal_sex"] = legalSexFromString(result.legal_sex);
    }
    return result;
  }

  async loginGoogle(userInfo: GoogleSignup): Promise<UserResponseDto> {
    let result: UserResponseDto | null = null;
    try {
      result = (await DocketApis().auth.google({ user: userInfo })).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, your sign in failed. Please try again later.");
    }
    return result;
  }

  async loginApple(userInfo: AppleSignUp): Promise<UserResponseDto> {
    let result: UserResponseDto | null = null;
    try {
      result = (await DocketApis().auth.apple({ user: userInfo })).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, your sign in failed. Please try again later.");
    }
    return result;
  }

  async getUser(): Promise<LegacyGetUserResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.getUser()).data;
    } catch (err: any) {
      this.throwResponseError(
        err,
        "Sorry, Docket could not get your user information at this time. Please try again later."
      );
    }
  }

  async initiateOidcAuth(stateIdentifier: string): Promise<void> {
    window.location.href = `${PROTOCOL}://${LEGACY_API_DOMAIN}/auth/${stateIdentifier}`;
    return Promise.resolve();
  }

  async loginWithAuthCode(code: string, stateIdentifier: string): Promise<UserResponseDto> {
    let result: UserResponseDto | null = null;
    try {
      result = (await DocketApis().auth.oidc(stateIdentifier, { code: code })).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, you were unable to login. Please try again later.");
    }
    if (result !== null) {
      // Super fun: legal sex is returned as a full string, e.g. "male", instead of "M" for this API.
      result["legal_sex"] = legalSexFromString(result["legal_sex"] || LegalSex.Unknown);
    }
    return result;
  }

  async deleteToken(refreshToken?: string): Promise<void> {
    try {
      return (
        await DocketApis().auth.removeRefreshTokenFamily({ refreshToken: refreshToken || null })
      ).data;
    } catch (err) {
      this.throwResponseError(err, "Docket was unable to revoke your tokens.");
    }
  }

  async addUserAccountPhone(request: LegacyVerifyUserPhoneRequestDto): Promise<void> {
    try {
      return (await DocketApis().railsLegacyV2.verifyUserPhone(request)).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to send you a code. Please try again later."
      );
    }
  }

  async verifyUserAccountPhone(request: LegacyVerifyUserPhoneCodeRequestDto): Promise<void> {
    try {
      return (await DocketApis().railsLegacyV2.verifyUserPhoneCode(request)).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to verify that code. Please try again later."
      );
    }
  }

  async deleteUser(): Promise<LegacyDeleteUserResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.deleteUser()).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to delete your account at this time. Please try again later."
      );
    }
  }

  async updateUser(params: any): Promise<LegacyUpdateUserResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.updateUser(params)).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to update your account. Please try again later."
      );
    }
  }

  // ==== IZ SearchList functions
  async enqueueIzSearch(
    request: LegacyEnqueueSearchRequestDto,
    jurisdiction: string
  ): Promise<LegacyEnqueueSearchResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.enqueueSearch(jurisdiction, request)).data;
    } catch (err: any) {
      if (err && err.response && err.response.status === 409) {
        throw new DocketAPIAlreadyEnqueuedError(err.response.data["error"]);
      } else {
        this.throwResponseError(
          err,
          "Sorry, Docket could not enqueue your search. Please try again later."
        );
      }
    }
  }

  async getIzSearch(
    searchUid: string,
    jurisdiction: string
  ): Promise<null | LegacyGetSearchDataDtoWithIntId> {
    let result: LegacyGetSearchDataDtoWithIntId;
    try {
      const response = await DocketApis().railsLegacyV2.getSearch(jurisdiction, searchUid);
      // 204 will never return data; it has no body
      if (response.status === 204) {
        // Not done de-enqueing yet
        return null;
      }
      // getIzSearch does NOT return an array, but data is a map instead with an APIData in it
      // And for some reason the backend is awful and returns this ID as a string, but the attributes as a number.
      result = {
        ...response.data["data"],
        id: parseInt(response.data["data"]["id"]),
      };
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket could not retrieve your search details. Please try again later."
      );
    }
    return result;
  }

  async deleteIzSearch(
    searchUid: string,
    jurisdiction: string
  ): Promise<LegacyDeleteSearchResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.deleteSearch(jurisdiction, searchUid)).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket could not delete your search right now. Please try again later."
      );
    }
  }

  async getIzSearches(iconTapped: boolean): Promise<LegacyGetSearchesResponseDtoWithIntId> {
    let result: LegacyGetSearchesResponseDtoWithIntId = { data: [] };
    try {
      let r = (await DocketApis().railsLegacyV2.getSearches(iconTapped.toString())).data;
      if (r.data && r.data.length > 0) {
        for (let i = 0; i < r.data.length; i++) {
          result.data.push({
            ...r.data[i],
            id: parseInt(r.data[i].id),
          });
        }
      }
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, we're unable to get your searches right now. Please try again later."
      );
    }
  }

  async sendImmunizationSearchPIN(
    searchUid: string,
    params: LegacyDeliverSearchPinRequestDto,
    jurisdiction: string
  ): Promise<LegacyDeliverSearchPinResponseDto> {
    let nonNullParams = params.sms ? 1 : 0;
    nonNullParams += params.email ? 1 : 0;
    nonNullParams += params.voice ? 1 : 0;
    if (nonNullParams !== 1) {
      throw new Error("You can only request one of SMS, Email, or Voice for PIN verification");
    }
    try {
      return (await DocketApis().railsLegacyV2.deliverSearchPin(jurisdiction, searchUid, params))
        .data;
    } catch (err) {
      //return Promise.reject(err?.response?.data['error'] ?? 'Sorry, we could not send a verification PIN. Please try again later.')
      this.throwResponseError(
        err,
        "Sorry, we could not send a verification PIN. Please try again later."
      );
    }
  }

  async verifyImmunizationSearchPIN(
    searchUid: string,
    params: LegacyVerifySearchPinRequestDto,
    jurisdiction: string
  ): Promise<LegacyVerifySearchPinResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.verifySearchPin(jurisdiction, searchUid, params))
        .data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, we could not verify that PIN right now. Please try again later."
      );
    }
  }

  // ==== IZ Record functions
  async getIzRecord(
    searchUid: string,
    jurisdiction: string
  ): Promise<LegacyGetRecordDataDtoWithIntId> {
    try {
      const response = (await DocketApis().railsLegacyV2.getRecord(jurisdiction, searchUid)).data;
      // Same problem as searches - IDs aren't numeric from the API
      return {
        ...response.data,
        id: parseInt(response.data.id),
      };
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve a specific immunization record. Please try again later."
      );
    }
  }

  // ==== Screening functions
  async getIzScreeningRecord(searchUid: string, jurisdiction: string): Promise<ScreeningDto[]> {
    try {
      return (await DocketApis().railsLegacyV2.getScreenings(jurisdiction, searchUid)).data
        .screenings;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve a specific immunization record. Please try again later."
      );
    }
  }

  async getAllIzRecords(): Promise<LegacyGetRecordsResponseDtoWithIntId> {
    let result: LegacyGetRecordsResponseDtoWithIntId = { data: [] };
    try {
      let r = (await DocketApis().railsLegacyV2.getRecords()).data;
      if (r.data && r.data.length > 0) {
        for (let i = 0; i < r.data.length; i++) {
          result.data.push({
            ...r.data[i],
            id: parseInt(r.data[i].id),
          });
        }
      }
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve all of your records right now. Please try again later."
      );
    }
  }

  async getRecordReport(
    searchUid: string,
    reportType: ReportType,
    jurisdiction: string
  ): Promise<ArrayBuffer> {
    /**
     * RIP. I could not get the OpenAPI generated client to stop thinking that the response type is a File
     */
    let result: ArrayBuffer;
    try {
      switch (reportType) {
        case ReportType.HISTORY_REPORT:
          result = (
            await DocketApis().railsLegacyV2.getHistoryReport(jurisdiction, searchUid, undefined, {
              responseType: "arraybuffer",
            })
          ).data as unknown as ArrayBuffer;
          break;
        case ReportType.SCHOOL_REPORT:
          result = (
            await DocketApis().railsLegacyV2.getSchoolReport(jurisdiction, searchUid, undefined, {
              responseType: "arraybuffer",
            })
          ).data as unknown as ArrayBuffer;
          break;
        case ReportType.FORECAST_REPORT:
          result = (
            await DocketApis().railsLegacyV2.getForecastReport(jurisdiction, searchUid, undefined, {
              responseType: "arraybuffer",
            })
          ).data as unknown as ArrayBuffer;
          break;
        case ReportType.PERSONAL_REPORT:
          result = (
            await DocketApis().railsLegacyV2.getPersonalImmunizationReport(
              jurisdiction,
              searchUid,
              undefined,
              {
                responseType: "arraybuffer",
              }
            )
          ).data as unknown as ArrayBuffer;
          break;
        default:
          assertNever(reportType);
      }
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve your report right now. Please try again later."
      );
    }
  }

  async refreshRecords(): Promise<number> {
    try {
      const response = await DocketApis().railsLegacyV2.refreshRecordsAsync();
      return response.data.estimated_wait_time;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't refresh your records at this time. Please try again later."
      );
    }
  }

  // === Provider configuration
  async getProviderConfigs(): Promise<LegacyGetProviderConfigsResponseDto> {
    try {
      return (await DocketApis().railsLegacyV2.getProviderConfigs()).data;
    } catch (err: any) {
      this.throwResponseError(
        err,
        "Docket encountered a problem retrieving important configuration. Please try again later."
      );
    }
  }
}

async function logoutUser(client: IDocketAPI): Promise<void> {
  await logout(client);
  window.location.href = "/";
}

let docketCareAPIV1: IDocketAPI | null = null;

/**
 * Get the default API client that is preconfigured to work.
 * @return the default client
 */
export function getAPIClient(): IDocketAPI {
  if (!docketCareAPIV1) {
    docketCareAPIV1 = new DocketCareAPIV1();
  }
  return docketCareAPIV1;
}
