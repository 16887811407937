import { ImmunizationRecord, ImmunizationSeries } from "../models/Interfaces";
import React, { useState } from "react";
import { infoLog } from "../utils/logger";
import { useTranslation } from "react-i18next";
import "./patientCard.css";
import DisclaimerCard from "./Disclaimers/DisclaimerCard";
import RecordCard from "./RecordCard";
import { useNavigate } from "react-router";
import { IconButton } from "./IconButton/IconButton";
import { Spinner } from "./spinner/Spinner";
import ScreeningRecordCard from "./ScreeningRecordCard";
import { completeVerifiedRecordsAtom } from "../jotai/atoms";
import { useAtom } from "jotai";

interface PatientCardProps {
  index: number;
  record: ImmunizationRecord;
  screening?: boolean;
}

export default function PatientCard({ record, index, screening }: PatientCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [completeRecords] = useAtom(completeVerifiedRecordsAtom);
  const completeRecord = completeRecords.find((x) => x.search.uid === record.searchUid)!;

  const selectItemHandler = async (record: ImmunizationRecord) => {
    infoLog(`searchObj ${JSON.stringify(record)}`);
    //await dispatch(setSearch(searchObj));
  };

  const goToReports = () => {
    navigate("reports", { state: { record: completeRecord.record } });
  };

  const ExpandedIcon = () => (
    <div onClick={() => setExpanded((currentExpanded) => !currentExpanded)} role="button">
      <i className="fa-solid fa-angle-down"></i>
    </div>
  );

  const CollapsedIcon = () => (
    <div onClick={() => setExpanded((currentExpanded) => !currentExpanded)} role="button">
      <i className="fa-solid fa-angle-up"></i>
    </div>
  );

  return (
    <div className="patient-card">
      <div className="level card-header pt-2 pb-2">
        <div className="level-left ml-4">
          <div
            role="paragraph"
            className="title is-5 has-text-white patient-name"
            data-testid="search-card-name"
          >
            {completeRecord.search.isChildSearch
              ? completeRecord.search.childFirstName
              : completeRecord.search.firstName}{" "}
            {completeRecord.search.isChildSearch
              ? completeRecord.search.childLastName
              : completeRecord.search.lastName}
          </div>
        </div>

        <div className="level-right is-flex is-flex-direction-row is-align-items-center mt-0 patient-name-controls">
          {!screening && (
            <>
              <div className="level-item has-text-centered mr-4 mb-0">
                <IconButton onClick={() => goToReports()} icon="pdf" alt={"pdf"} />
              </div>
              <div className="level-item has-text-centered mr-4 mb-0">|</div>
            </>
          )}
          <div className="level-item mr-5 is-size-4 mt-1 mb-0">
            {expanded === true ? <ExpandedIcon /> : <CollapsedIcon />}
          </div>
        </div>
      </div>
      <li>
        {expanded && (
          <div className="columns is-multiline is-align-items-stretch">
            {screening ? (
              <>
                <div
                  className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column"
                  key={"disclaimer"}
                >
                  <DisclaimerCard provider={completeRecord.search.izProviderKey} screening />
                </div>
                {completeRecord.screening?.events.map((screeningEvent, index) => (
                  <div
                    className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column"
                    key={index}
                  >
                    <ScreeningRecordCard
                      key={index}
                      screening={completeRecord.screening!}
                      screeningEvent={screeningEvent}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column">
                  <DisclaimerCard provider={completeRecord.record.izProviderKey} />
                </div>
                {completeRecord.record.immunizations.map(
                  (imm: ImmunizationSeries, index: number) => (
                    <div
                      className="column is-one-third-desktop is-half-tablet is-flex is-flex-direction-column"
                      key={index}
                    >
                      <RecordCard
                        index={index}
                        record={completeRecord.record}
                        series={imm}
                        onSelect={() => {
                          selectItemHandler(completeRecord.record);
                        }}
                      />
                    </div>
                  )
                )}
                {(!completeRecord || !completeRecord.record.dequeuedAt) && (
                  <div className="ml-2 mt-2 has-text-dark is-flex is-align-items-center">
                    {" "}
                    <Spinner />{" "}
                    <b className="pl-4 is-size-5">{t("immunizations.search_records_loading")}</b>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </li>
    </div>
  );
}
