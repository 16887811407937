import localforage from "localforage";
import { TextEncoder, TextDecoder } from "web-encoding";
import { UserInfo } from "./models/Interfaces";
import { Buffer } from "buffer";

export const config = { name: "DocketAppV1" };

const CryptoOpts = {
  // In 2023 OWASP recommends 600k iterations for SHA-256 keys.
  // Let's just add some buffer.
  PBKDF2_ITERATIONS: 1_000_000,
  SALT_LENGTH: 256,
  AES_GCM_LENGTH: 256,
  AES_KW_LENGTH: 256,
};

export enum Key {
  EncryptionKey = "EncryptionKey_V1_AES-GCM_256",
  EncryptionSaltKey = "EncryptionSalt_V1",
  // Still use "UserAccount" which was the legacy key name for all user info including tokens.
  UserInfo = "UserAccount",
  Tokens = "tokens",
  Login = "persist:login",
}

export enum Platform {
  Browser = "Browser",
  iOS = "iOS",
  Android = "Android",
}

export enum EncryptionVersion {
  AES_GCM_256 = "AES-GCM_256",
}

type EncryptedPayload = {
  payload: ArrayBuffer;
  iv: Uint8Array;
  // Version exists to mark what type of encryption we used for this payload.
  // This lets us upgrade in the future.
  version: EncryptionVersion;
  // Specifies if this should be in iOS, Android, or a browser
  platform: Platform;
};

export class NoEncryptionKeyError extends Error {
  constructor(msg?: string) {
    super(
      `${msg ? msg + " " : ""}There is no client encryption key. Please set a user account first.`
    );
  }
}

/**
 * EncryptedStorage is an API wrapper around localForage that ensures all
 * data is encrypted when written, and decrypted on read.
 */
export class EncryptedStorage implements LocalForageDbMethodsCore {
  store: LocalForage;
  cryptoStore: LocalForage;
  clientCryptoKey: CryptoKey | null;

  constructor(options: LocalForageOptions) {
    if (options && options.name === "CryptoStoreV1") {
      throw new Error("Cannot create a store with the reserved name 'CryptoStoreV1'");
    }

    this.store = localforage.createInstance(options);
    this.cryptoStore = localforage.createInstance({ name: "CryptoStoreV1" });
    this.clientCryptoKey = null;
  }

  async getEncryptionKey(): Promise<CryptoKey> {
    if (this.clientCryptoKey) {
      return Promise.resolve(this.clientCryptoKey);
    }
    throw new NoEncryptionKeyError();
  }

  generateIV(): Uint8Array {
    // https://developer.mozilla.org/en-US/docs/Web/API/AesGcmParams
    return window.crypto.getRandomValues(new Uint8Array(12));
  }

  async serialize<T>(val: T): Promise<string> {
    return Promise.resolve(JSON.stringify(val));
  }

  // Note that `deserialize` does NOT completely work for classes.
  // There's a long reason for this that relates to type erasure in TS, but
  // the short version is that methods don't get hydrated back onto the object after parsing.
  // I thought JS was like "whatever it fits this shape here are some functions that work" but NOPE.
  async deserialize<T>(val: string): Promise<T> {
    return Promise.resolve(JSON.parse(val));
  }

  async encrypt(plaintext: string): Promise<EncryptedPayload> {
    const encoder = new TextEncoder();
    const buf = encoder.encode(plaintext);

    const key = await this.getEncryptionKey();
    const iv = this.generateIV();
    const ciphertext = await window.crypto.subtle.encrypt({ name: "AES-GCM", iv: iv }, key, buf);
    return {
      payload: ciphertext,
      iv: iv,
      version: EncryptionVersion.AES_GCM_256,
      platform: Platform.Browser,
    };
  }

  async decrypt(ciphertext: ArrayBuffer, iv: Uint8Array): Promise<string> {
    const key = await this.getEncryptionKey();
    const plaintextBuf = await window.crypto.subtle.decrypt(
      { name: "AES-GCM", iv: iv },
      key,
      ciphertext
    );

    const decoder = new TextDecoder();
    const plaintext = decoder.decode(plaintextBuf);
    return plaintext;
  }

  async getItem<T>(key: string, callback?: (err: any, value: T | null) => void): Promise<T | null> {
    try {
      const encryptedItem = await this.store.getItem<EncryptedPayload>(key);
      if (encryptedItem === null) {
        if (callback !== undefined && callback !== null) {
          callback(null, null);
        }
        return null;
      }

      let decrypted: string;
      try {
        decrypted = await this.decrypt(encryptedItem.payload, encryptedItem.iv);
      } catch (err: any) {
        if (err instanceof NoEncryptionKeyError) {
          throw new NoEncryptionKeyError(`Could not getItem for key '${key}'.`);
        }
        throw err;
      }
      const deserialized = await this.deserialize<T>(decrypted);

      if (callback !== undefined && callback !== null) {
        callback(null, deserialized);
      }
      return deserialized;
    } catch (e: any) {
      if (callback !== undefined && callback !== null) {
        callback(e, null);
      }
      throw e;
    }
  }

  async setItem<T>(key: string, value: T, callback?: (err: any, value: T) => void): Promise<T> {
    try {
      const serializedVal = await this.serialize(value);
      let encryptedVal: EncryptedPayload;
      try {
        encryptedVal = await this.encrypt(serializedVal);
      } catch (err: any) {
        if (err instanceof NoEncryptionKeyError) {
          throw new NoEncryptionKeyError(`Could not set key '${key}'.`);
        }
        throw err;
      }
      await this.store.setItem<EncryptedPayload>(key, encryptedVal);
      if (callback !== undefined && callback !== null) {
        callback(null, value);
      }
      return value;
    } catch (e: any) {
      if (callback !== undefined && callback !== null) {
        callback(e, value);
      }
      throw e;
    }
  }

  public async updateCryptoKey(u: UserInfo, password: string): Promise<void> {
    if (!u || !password || !u.client_key) {
      // Can't do much without both the password and client_key
      throw new Error("Attempted to update the crypto key without a user, client_key, or password");
    }

    // Generate a key for secret derivation
    const keyAsBytes = Buffer.from(u.client_key, "base64");
    const importedHkdf = await window.crypto.subtle.importKey("raw", keyAsBytes, "HKDF", false, [
      "deriveBits",
      "deriveKey",
    ]);
    const hkdfSalt = window.crypto.getRandomValues(new Uint8Array(CryptoOpts.SALT_LENGTH));
    const hkdfParams = {
      name: "HKDF",
      hash: "SHA-256",
      salt: hkdfSalt,
      info: new TextEncoder().encode(`DocketPWAWebClient_${u.email || "unknown-email"}`),
    };
    const derivedKeyParams = { name: "AES-GCM", length: CryptoOpts.AES_GCM_LENGTH };
    // We need this key to be extractable since it is later wrapped.
    let derivedKey: CryptoKey;
    try {
      derivedKey = await window.crypto.subtle.deriveKey(
        hkdfParams,
        importedHkdf,
        derivedKeyParams,
        true,
        ["encrypt", "decrypt"]
      );
    } catch (e: any) {
      console.log(e);
      throw e;
    }

    const curItems = new Map<string, any>();
    if (this.clientCryptoKey) {
      // If we already have a key we need to decrypt everything in the database with the current key, then re-encrypt it once stored.
      // This happens when a user changes their password.
      // Don't remove/re-write the key or salt here
      const keys: string[] = (await this.keys()).filter(
        (k) => k !== Key.EncryptionKey && k !== Key.EncryptionSaltKey
      );
      for (let i = 0; i < keys.length; i++) {
        // eslint-disable-next-line security/detect-object-injection
        const key = keys[i];
        curItems.set(key, await this.getItem(key));
      }
    } else {
      // No key yet - we need to build everything on the client_key
      // If we're creating a new crypto key we should clear the store to ensure all cached data is gone.
      await this.store.clear();
    }

    // The crypto key should NEVER BE WRITTEN directly to storage! We must first wrap the key with the password. See #wrapKey.
    this.clientCryptoKey = derivedKey;

    // Re-write everything to storage, re-encrypting it all
    const pendingSetPromises: Promise<any>[] = [];
    for (let [k, v] of curItems.entries()) {
      pendingSetPromises.push(this.setItem(k, v));
    }
    await Promise.all(pendingSetPromises);

    const wrappedSalt: Uint8Array = window.crypto.getRandomValues(
      new Uint8Array(CryptoOpts.SALT_LENGTH)
    );
    const wrappedKey = await this.wrapKey(this.clientCryptoKey, wrappedSalt, password);
    const b64Salt = Buffer.from(wrappedSalt).toString("base64");
    const b64Key = Buffer.from(wrappedKey).toString("base64");
    await this.cryptoStore.setItem(Key.EncryptionSaltKey, b64Salt);
    await this.cryptoStore.setItem(Key.EncryptionKey, b64Key);
  }

  private async wrapKey(
    clientCryptoKey: CryptoKey,
    salt: Uint8Array,
    password: string
  ): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const passwordKey: CryptoKey = await window.crypto.subtle.importKey(
      "raw",
      encoder.encode(password),
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );

    const wrappingKey: CryptoKey = await window.crypto.subtle.deriveKey(
      { name: "PBKDF2", salt, iterations: CryptoOpts.PBKDF2_ITERATIONS, hash: "SHA-256" },
      passwordKey,
      { name: "AES-KW", length: CryptoOpts.AES_KW_LENGTH },
      true,
      ["wrapKey", "unwrapKey"]
    );
    return await window.crypto.subtle.wrapKey("raw", clientCryptoKey, wrappingKey, {
      name: "AES-KW",
    });
  }

  private async unwrapKey(wrappedKey: Buffer, salt: Buffer, password: string): Promise<CryptoKey> {
    const encoder = new TextEncoder();
    const passwordKey: CryptoKey = await window.crypto.subtle.importKey(
      "raw",
      encoder.encode(password),
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );

    const wrappingKey: CryptoKey = await window.crypto.subtle.deriveKey(
      { name: "PBKDF2", salt, iterations: CryptoOpts.PBKDF2_ITERATIONS, hash: "SHA-256" },
      passwordKey,
      { name: "AES-KW", length: CryptoOpts.AES_KW_LENGTH },
      true,
      ["wrapKey", "unwrapKey"]
    );

    return await window.crypto.subtle.unwrapKey(
      "raw",
      wrappedKey,
      wrappingKey,
      { name: "AES-KW" },
      { name: "AES-GCM", length: CryptoOpts.AES_GCM_LENGTH },
      true,
      ["encrypt", "decrypt"]
    );
  }

  /**
   * Check if the database is an encrypted, locked state where data cannot be read.
   *
   * @returns true if locked, false otherwise
   */
  locked(): boolean {
    return !this.clientCryptoKey;
  }

  /**
   * Unlock the encrypted database using the user's password.
   *
   * @param password The password for the user's account.
   * @returns True if unlocked, false otherwise.
   * @throws Error - Cipher job failed if it could not be unlocked.
   */
  async unlock(password: string): Promise<boolean> {
    const b64WrappedSaltBytes: string | null = await this.cryptoStore.getItem<string>(
      Key.EncryptionSaltKey
    );

    const b64WrappedKeyBytes: string | null = await this.cryptoStore.getItem<string>(
      Key.EncryptionKey
    );

    if (!b64WrappedKeyBytes || !b64WrappedSaltBytes) {
      throw new Error("Unable to find a stored encryption key or salt. Please log out and log in.");
    }

    let saltBytes = Buffer.from(b64WrappedSaltBytes, "base64");
    let keyBytes = Buffer.from(b64WrappedKeyBytes, "base64");

    const unwrappedKey = await this.unwrapKey(keyBytes, saltBytes, password);
    this.clientCryptoKey = unwrappedKey;
    return true;
  }

  async removeItem(key: string, callback?: (err: any) => void): Promise<void> {
    return this.store.removeItem(key, callback);
  }

  async clear(callback?: (err: any) => void): Promise<void> {
    return this.store.clear(callback);
  }

  async length(callback?: (err: any, numberOfKeys: number) => void): Promise<number> {
    return this.store.length(callback);
  }

  async key(keyIndex: number, callback?: (err: any, key: string) => void): Promise<string> {
    return this.store.key(keyIndex, callback);
  }

  async keys(callback?: (err: any, keys: string[]) => void): Promise<string[]> {
    return this.store.keys(callback);
  }

  async iterate<T, U>(
    iteratee: (value: T, key: string, iterationNumber: number) => U,
    callback?: (err: any, result: U) => void
  ): Promise<U> {
    return Promise.reject(new Error("Iterate method not implemented."));
  }
}

let store: EncryptedStorage | null = null;

export function configDb() {
  localforage.config(config);
  setDbStore(new EncryptedStorage(config));
}

export function setDbStore(newDbStore: EncryptedStorage) {
  store = newDbStore;
}

export default function db(): EncryptedStorage {
  if (store) {
    return store;
  }

  setDbStore(new EncryptedStorage(config));
  return store!;
}
