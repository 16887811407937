import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
//import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router";
import PinField from "react-pin-field";
import "./enterPinForm.scss";
import { DocketAPIError, getAPIClient } from "../../apiClient";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { selectedSearchAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";
import { fetchRecordsUntilUpdated } from "../../utils/polling";

export function EnterPinForm() {
  //  const search = useAppSelector((store) => store.immunizations.search);
  const [search] = useAtom(selectedSearchAtom);

  const lRef = useRef<HTMLInputElement[]>([]);
  const rRef = useRef<HTMLInputElement[]>([]);

  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean | string>(false);

  //  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setCompleted(code.length === 8);
  }, [code, completed]);

  function getHeader() {
    return (
      <div className="level tab-header is-white is-marginless">
        <div className="level-left ml-4 has-text-dark has-text-left">
          <h2>{t("immunizations.pin_entry_header")}</h2>
        </div>
      </div>
    );
  }

  const getMessage1Text = () => {
    return t("immunizations.alt_contacts_header2").split("|");
  };

  const getInfoText = () => {
    return t("immunizations.pin_entry_header2_alt").split("|");
  };

  const disableButton = () => {
    return !completed;
  };

  const continueFromAlert = async () => {
    if (disableButton() || search === null) {
      return;
    }

    try {
      await getAPIClient().verifyImmunizationSearchPIN(
        search.uid,
        { pin: code },
        search.izProviderKey
      );
      /**
       * Do not await this since it could potentially take a long time to
       * complete and we want to navigate immediately.
       */
      fetchRecordsUntilUpdated();
      navigate("../results", { state: { isScenarioB: false } });
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorModal(e.message);
      }
    }
  };

  return (
    <>
      {getHeader()}
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>{showErrorModal}</ErrorModal>
      )}
      <div className="section has-text-dark">
        <div className="columns is-centered">
          <div className="is-flex is-flex-direction-row" />
          <PinField
            ref={lRef}
            className="pin-field has-text-dark first"
            autoFocus
            onChange={setCode}
            length={8}
            validate="0123456789"
            format={(k) => k.toUpperCase()}
            inputMode="numeric"
          />
        </div>
        <br />
        <p>{getInfoText().join(" ")}</p>
        <button
          type="submit"
          className="mt-5 button docket-button pl-5 pr-5"
          onClick={() => continueFromAlert()}
          {...(disableButton() && { disabled: true })}
        >
          {t("generic.verify")}
        </button>
      </div>
    </>
  );
}

export default EnterPinForm;
