import { ImmunizationRecord } from "../../models/Interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../components/patientCard.css";
import ReportCard from "../../components/ReportCard";
import { getReportForState } from "../../utils/reportUtils";
import { useLocation } from "react-router-dom";
import getIzProvider from "../../models/providers/IzProviders";

interface ReportListProps {
  record: ImmunizationRecord;
}

export default function ReportList() {
  const { state } = useLocation();
  const { record } = (state as ReportListProps) || {};
  const { t } = useTranslation();
  const providerKey: string = record?.izProviderKey?.toLowerCase() || "";

  const izProvider = getIzProvider(providerKey, t);
  const reportTitles = izProvider.reportTitles;

  const noPatient = () => {
    return (
      <li className="mb-4" key="0">
        {t("immunizations.reports_no_patient")}
      </li>
    );
  };

  const reports = () => {
    return reportTitles.map((title, index) => (
      <li className="mb-4" key={index}>
        <ReportCard
          reportType={getReportForState(providerKey, index)}
          reportTitle={title}
          record={record}
        />
      </li>
    ));
  };

  return (
    <div className="section ml-1 mr-1 mt-1">
      <ul>{record ? reports() : noPatient()}</ul>
    </div>
  );
}
