import { Configuration } from "@docket/consumer-app-client-typescript-axios";
import { ITokenProvider } from "../../models/TokenProvider";
import { baseConfigOptions } from "./base-config";

export default (provider: ITokenProvider) => {
  return new Configuration({
    ...baseConfigOptions,
    accessToken: async () => (await provider.tokens())?.access || "",
  });
};
