import React, { useEffect, useState } from "react";
import "./login.css";
import { DocketAPIError, getAPIClient } from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import db, { Key } from "../../database";
//import { useAppDispatch } from "../../hooks";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { errorLog, infoLog } from "../../utils/logger";
import DatePicker from "../../components/form/DatePicker";
import {
  checkBoxValueMap,
  getLocalizedLegalSexChoices,
  getHorizontalRadioSection,
} from "../../utils/formUtils";
import moment from "moment/moment";
import lodash from "lodash";
import { UserInfo as UserInfoType } from "../../models/Interfaces";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { userAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";

export function UserInfo() {
  //  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();

  const [dob, setDob] = useState<Date | null>(moment().subtract(18, "years").toDate());
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false);
  // DOB checkbox, to map selection to values
  // TODO: set initial state from prev search or user object
  const [checkboxValue, setCheckboxValue] = useState(getLocalizedLegalSexChoices(t));
  const checkboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(checkboxValue, index);
    infoLog(`newValue ${JSON.stringify(newValue)}`);
    setCheckboxValue(newValue);
  };

  const [currentUser, setCurrentUser] = useAtom(userAtom);

  const { handleSubmit } = useForm();

  const searchFormTranslations = {
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
  };

  const ageCheckPassed = () => {
    if (dob && moment().subtract(13, "years").isBefore(moment(dob), "day")) {
      return false;
    }
    return true;
  };

  const onSubmit = async (data: any) => {
    if (!ageCheckPassed()) {
      errorLog({ message: t("settings.dob_error_message") } as Error);
      setShowErrorMessage(`${t("settings.dob_error_message")}`);
      return;
    }
    try {
      let sex = lodash.find(checkboxValue, ["checked", true]);
      let legalSex = sex?.value ?? "U";
      const params = {
        legal_sex: legalSex,
        dob: moment(dob).toISOString(),
        language: "en",
      };
      const res = await getAPIClient().updateUser(params);
      // save user info to db
      if (res) {
        await db().setItem(Key.UserInfo, {
          ...currentUser,
          legal_sex: legalSex,
          dob: moment(dob).format("L"),
          language: "en",
        } as UserInfoType);
        navigate("../verifyphone");
      }
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  const getUserUpdateForm = () => {
    return (
      <div>
        {showErrorMessage && (
          <ErrorModal onCloseModal={() => setShowErrorMessage(false)}>
            {showErrorMessage}
          </ErrorModal>
        )}
        <label className="label form-input-label is-size-6">
          {searchFormTranslations.dateOfBirth}
        </label>
        <DatePicker key="dateOfBirth" selectedDate={dob} setSelectedDate={(d) => setDob(d)} />
        <br />
        <br />
        {getHorizontalRadioSection(
          checkboxValue,
          searchFormTranslations.legalSex,
          checkboxHandler,
          "form-input-label",
          "gender",
          t
        )}
        <br />
      </div>
    );
  };

  return (
    <>
      <h1 className="has-text-dark is-size-4">{t("onboarding.signup_header")}</h1>
      <div className="separator"></div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="is-flex is-flex-direction-column is-justify-content-space-between is-fullheight"
      >
        {getUserUpdateForm()}

        <div className="is-flex is-flex-direction-column is-align-items-center">
          <button className="button docket-button is-fullwidth " type="submit" disabled={false}>
            {t("generic.continue").toUpperCase()}
          </button>
        </div>
      </form>
    </>
  );
}

export default UserInfo;
