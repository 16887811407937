import { Configuration } from "@docket/consumer-app-client-typescript-axios";
import { API_DOMAIN, PROTOCOL } from "../../globals";
import VERSION from "../../version";

export const baseConfigOptions: Pick<Configuration, "basePath" | "baseOptions"> = {
  basePath: `${PROTOCOL}://${API_DOMAIN}`,
  baseOptions: {
    headers: {
      "X-User-Agent": `DocketPWA/1.0-${VERSION}`,
    },
  },
};

export default () => new Configuration(baseConfigOptions);
