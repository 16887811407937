import { ScreeningRecord } from "../models/Interfaces";
import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./recordCard.css";
import { useNavigate } from "react-router";
import { ErrorModal } from "./modals/ErrorModal";
import { ScreeningDto } from "@docket/consumer-app-client-typescript-axios";

interface RecordCardProps {
  screening: ScreeningRecord;
  screeningEvent: ScreeningDto;
}

// Virus images
const lead = "/assets/images/Icons_Screening_Lead.png";

export default function ScreeningRecordCard({ screening, screeningEvent }: RecordCardProps) {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const emptyCard = screening.events.length < 1;
  const type = emptyCard ? "empty" : screening.type.toLowerCase();

  const goToEvents = () => {
    if (emptyCard) {
      setModalIsOpen(true);
    } else {
      navigate("../screeningevents", { state: { screeningEvent: screeningEvent } });
    }
  };

  return (
    <>
      {modalIsOpen && (
        <ErrorModal
          title={t("screening.records_no_events_header")}
          onCloseModal={() => setModalIsOpen(false)}
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {t("screening.records_no_events_message")}
            <a
              href="https://nj.gov/health/childhood-lead"
              target="_blank"
              className="docket-link has-text-link"
            >
              nj.gov/health/childhood-lead
            </a>
            .
          </p>
        </ErrorModal>
      )}
      <div onClick={() => goToEvents()} className="is-fullheight">
        <div className="columns is-gapless record-card is-justify-content-space-between">
          <div
            className={`${emptyCard ? "noRecordsStatus" : "recordStatus"} record-badge-bg`}
          ></div>
          <div className={`record-badge`}>{t("screening.records_status", { context: type })}</div>

          <div className="column is-7 ml-6">
            <div
              role="paragraph"
              className="card-title pt-2 has-text-weight-semibold is-family-primary has-text-dark mt-1"
              data-testid="record-card-title"
            >
              {t("screening.lead_screening_test")}
            </div>
            {!emptyCard && (
              <div
                role="paragraph"
                className="is-size-6 is-family-primary has-text-dark "
                data-testid="record-card-description"
              >
                {t("screening.records_tested")}
                {moment.parseZone(screening.latestTestDate).format("L")}
              </div>
            )}
            <div
              role="paragraph"
              className={`is-size-7 has-text-weight-bold record-card-description ${
                emptyCard && "mt-1"
              }`}
              data-testid="record-card-description"
            >
              {screening.events?.length || "No"} {t("screening.records_test")}
              {(screening.events?.length || 0) !== 1 ? `s` : ""}
            </div>
          </div>

          <div className="column is-2 mb-4 mr-4 has-text-right is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-end">
            <img className="record-card-right-img" src={lead} alt={`Lead Image`} />
          </div>
        </div>
      </div>
    </>
  );
}
