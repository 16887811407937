import { getAPIClient } from "../apiClient";
import {
  appSettingsAtom,
  izRecordAPIResultAtomFamily,
  izSearchAPIResultAtom,
  screeningsAPIResultAtomFamily,
} from "./atoms";
import { errorLog } from "../utils/logger";
import { globalStore } from "./store";

export async function updateSearchAtoms(refresh: boolean): Promise<boolean> {
  try {
    const searches = await getAPIClient().getIzSearches(refresh);
    if (!searches.data) {
      return true;
    }

    await globalStore.set(izSearchAPIResultAtom, searches.data);
    // If we updated the searches we should also update the records
    return updateRecordAtoms();
  } catch (error: any) {
    // Handle error here, should be silent though
    errorLog(error, "Could not update search atoms");
    return false;
  }
}

export async function updateRecordAtoms(): Promise<boolean> {
  try {
    const searches = await globalStore.get(izSearchAPIResultAtom);

    if (!searches) {
      return true;
    }

    for (const search of searches) {
      const records = await getAPIClient().getIzRecord(
        search.attributes.uid,
        search.attributes.iz_provider_key
      );

      // Use the family of atoms for records
      const izRecordAPIResultAtom = izRecordAPIResultAtomFamily(search.attributes.uid);
      await globalStore.set(izRecordAPIResultAtom, records);
    }

    return await updateScreeningAtom();
  } catch (error: any) {
    errorLog(error, "Could not update record atoms");
    return false;
  }
}

export async function updateScreeningAtom(): Promise<boolean> {
  try {
    const searches = await globalStore.get(izSearchAPIResultAtom);
    if (!searches) {
      return true;
    }

    for (const search of searches) {
      const screenings = await getAPIClient().getIzScreeningRecord(
        search.attributes.uid,
        search.attributes.iz_provider_key
      );

      const izScreeningAPIResultAtom = screeningsAPIResultAtomFamily(search.attributes.uid);
      await globalStore.set(izScreeningAPIResultAtom, screenings);
    }

    return true;
  } catch (error: any) {
    errorLog(error, "Could not update screening atom");
    return false;
  }
}

export async function clearPrefs(): Promise<void> {
  // We want to preserve the firebase emulator setting and whether or not this has NJ features
  const currentSettings = await globalStore.get(appSettingsAtom);
  await globalStore.set(appSettingsAtom, {
    ...currentSettings,
    email: "",
    authorized: false,
    signedInWithFirebase: false,
  });
}
