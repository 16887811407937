import i18next from "i18next";
import moment from "moment";
import { API_DOMAIN, PROTOCOL } from "../globals";
import db from "../database";
import { infoLog } from "./logger";
import { LegacyProviderConfigDto } from "@docket/consumer-app-client-typescript-axios";

// Oh god why
// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
// http://emailregex.com/
export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const pdfViewer = "view_pdf?name=";
//let embed = "https://docs.google.com/gview?embedded=true&url="

export const userPinAttempts = "userPinAttempts";
export const userPinAllowedDate = "userPinAllowedDate";

export const androidImmunizationsFaqPdfURL = `${PROTOCOL}://${API_DOMAIN}/${pdfViewer}Docket_Immunizations_FAQs_Overview.pdf`;
export const androidImmunizationsMultiMatchPdfURL = `${PROTOCOL}://${API_DOMAIN}/${pdfViewer}Docket_Immunizations_MULTI_MATCH_Help.pdf`;
export const androidImmunizationsNoMatchPdfURL = `${PROTOCOL}://${API_DOMAIN}/${pdfViewer}Docket_Immunizations_NO_MATCH_Help.pdf`;
export const androidImmunizationsUtahPinHelpPdfURL = `${PROTOCOL}://${API_DOMAIN}/${pdfViewer}Docket_Utah_PIN_Help.pdf`;
export const androidImmunizationsNJPrivacyPdfURL = `${PROTOCOL}://${API_DOMAIN}/${pdfViewer}NJ_Privacy_Notice.pdf`;

export const immunizationsFaqPdfURL = `${PROTOCOL}://${API_DOMAIN}/Docket_Immunizations_FAQs_Overview.pdf`;
export const immunizationsMultiMatchPdfURL = `${PROTOCOL}://${API_DOMAIN}/Docket_Immunizations_MULTI_MATCH_Help.pdf`;
export const immunizationsNoMatchPdfURL = `${PROTOCOL}://${API_DOMAIN}/Docket_Immunizations_NO_MATCH_Help.pdf`;
export const immunizationsUtahPinHelpPdfURL = `${PROTOCOL}://${API_DOMAIN}/Docket_Utah_PIN_Help.pdf`;
export const immunizationsNJPrivacyPdfURL = `${PROTOCOL}://${API_DOMAIN}/NJ_Privacy_Notice.pdf`;
export const docketPrivacyPdfURL = `${PROTOCOL}://${API_DOMAIN}/privacy_policy`;
export const docketTOSPdfURL = `${PROTOCOL}://${API_DOMAIN}/terms_of_use`;

export const virusFullNames = [
  "COVID-19",
  "Hepatitis B",
  "Rotavirus",
  "Diphtheria, Tetanus, Pertussis",
  "Haemophilus influenzae B",
  "Pneumococcal",
  "Polio",
  "Influenza",
  "Measles, Mumps, Rubella",
  "Varicella (Chickenpox)",
  "Hepatitis A",
  "Human Papillomavirus (HPV)",
  "Meningococcal",
  "Meningococcal B",
  "Zoster (Shingles)",
  "Yellow Fever",
  "Typhoid",
  "Monkeypox",
];

export const setPinAllowedDate = async (date: string) => {
  await db().setItem(userPinAllowedDate, date);
};

export const getPinAllowedDate = async () => {
  return (await db().getItem(userPinAllowedDate)) ?? null;
};

export const setPinAttempts = async (reset: boolean) => {
  const attempts = await getPinAttempts();
  infoLog(`pin attempts ${attempts}`);
  await db().setItem(userPinAttempts, reset ? 0 : attempts + 1);
};

export const getPinAttempts = async () => {
  const value: number | null = await db().getItem(userPinAttempts);
  const parsed = value ? value : 0;
  return parsed;
};

export const sortApiConfigAscending = (
  config: LegacyProviderConfigDto[]
): LegacyProviderConfigDto[] => {
  if (config) {
    return config.sort(function (a, b) {
      let textA = a.iz_provider.destination_id.toUpperCase();
      let textB = b.iz_provider.destination_id.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
  return [] as LegacyProviderConfigDto[];
};

export const formatNumber = (pn: string) => {
  if (pn.length === 10) {
    return `(${pn.slice(0, 3)}) ${pn.slice(3, 6)}-${pn.slice(6)}`;
  }
  // potentially malformed or intl number
  return pn;
};

export const pwdCheck = (pwd: string) => {
  if (pwd.length < 8) {
    return "At least eight (8) characters long, please!";
  } else if (pwd.length > 50) {
    return "No more than fifty (50) characters, please!";
  } else {
    let totalMatches = 0;
    totalMatches += /[a-z]/.test(pwd) ? 1 : 0; // lower
    totalMatches += /[A-Z]/.test(pwd) ? 1 : 0; // upper
    totalMatches += /[0-9]/.test(pwd) ? 1 : 0; // number
    totalMatches += /[^a-zA-Z0-9]/.test(pwd) ? 1 : 0; // symbol
    if (totalMatches >= 2) {
      return "";
    }
    return "Passwords must be 8+ characters and contain at least 1: uppercase, number, or special character!";
  }
};

export const readableDate = (date: number | Date | string) => {
  return moment(date).format("YYYY-MM-DD");
};
